import React from 'react'
const LabelValue = Loader.loadBaseComponent('LabelValue')

export default ({ captureTime, deviceName, aid2CaptureTime }) => {
  return (
    <div className="box-container">
      <div className="header">
        <LabelValue className="item" label="抓拍设备" value={deviceName ? deviceName : '-'} />
        <LabelValue className="item" label="抓拍时间" value={Utils.formatTimeStamp(parseInt(captureTime, 10))} />
        <LabelValue className="item" label="同行抓拍时间" value={Utils.formatTimeStamp(parseInt(aid2CaptureTime, 10))} />
      </div>
    </div>
  )
}

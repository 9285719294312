import React from 'react'
import HeaderView from './components/header'
import ImageView from './components/ImageView'
const NoData = Loader.loadBaseComponent('NoData')
const ImageMovieMap = Loader.loadBusinessComponent('ImageMovieMap')
const Wrapper = Loader.loadBusinessComponent('BaseLibDetails', 'Wrapper')
const PageDetails = Loader.loadBusinessComponent('PageDetails')

export default class BodyLibraryDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      currentItem: {}
    }
  }
  componentWillMount() {
    try {
      this.initRequest()
    } catch (e) {
      console.error(e)
      this.setState({ loading: false })
    }
  }

  initRequest = async () => {
    const { location } = this.props
    const { id } = Utils.queryFormat(location.search)
    const result = await window.LM_DB.get('parameter', id)
    if (!result) {
      return
    }
    const { list, currentItem } = result
    this.setState({ list, currentItem })
  }

  /**
   * @desc 点击切换
   */
  changeActiveId = (activeId, type) => {
    const item = this.state.list.find(v => v.id === activeId)
    this.setState({ currentItem: item })
  }

  render() {
    const { list, currentItem } = this.state

    if (!!!list.length) {
      return <NoData />
    }
    const index = list.findIndex(v => v.id === currentItem.id)
    //上一条数据
    const preData = list[index - 1]
    //下一条第一条数据
    const nextData = list[index + 1]
    return (
      <Wrapper>
        <div style={{ width: '100%', height: '100%', minHeight: 400 }}>
          <HeaderView {...currentItem} captureTime={currentItem.aid1CaptureTime} />
          <div className="picture-container">
            {preData && (
              <div className="nav-l">
                <PageDetails imgUrl={preData.aid1FaceUrl} onChange={this.changeActiveId} id={preData.id} pageType="pre" />
              </div>
            )}
            {nextData && (
              <div className="nav-r">
                <PageDetails imgUrl={nextData.aid1FaceUrl} id={nextData.id} onChange={this.changeActiveId} />
              </div>
            )}
            <ImageView currentItem={currentItem} key={`${currentItem.aid1CaptureImgId}-${currentItem.aid2CaptureImgId}`}/>
            
          </div>
        </div>
      </Wrapper>
    )
  }
}

import React from 'react'
const ImageMovieMap = Loader.loadBusinessComponent('ImageMovieMap')

export default function ImageView({ currentItem }) {
  return (
    <>
      <ImageMovieMap
        data={{
          sceneUrl: currentItem.aid1CaptureImg,
          imgId: currentItem.aid1CaptureImgId,
          cid: currentItem.cid,
          captureTime: currentItem.aid1CaptureTime,
          ...currentItem
        }}
        key={'aid1'}
      />
      {currentItem.aid1CaptureId !== currentItem.aid2CaptureId && (
        <ImageMovieMap
          data={{
            sceneUrl: currentItem.aid2CaptureImg,
            imgId: currentItem.aid2CaptureImgId,
            cid: currentItem.cid,
            captureTime: currentItem.aid2CaptureTime,
            ...currentItem
          }}
          key={'aid2'}
        />
      )}
    </>
  )
}
